<template>
  <div class="navbar-container d-flex justify-content-between">
    <!--
      <b-navbar-nav class="nav align-items-center ml-auto">
        <dark-Toggler class="d-sm-block" />
        <user-dropdown />
      </b-navbar-nav>
    -->

    <div class="nav nav-item d-xl-none"></div>
    <b-navbar-brand href="/">
      <b-img
        :src="require('@/assets/images/logo/top_logo.png')"
        width="280"
        class="mr-auto"
      />
    </b-navbar-brand>
    <div class="nav nav-item d-xl-none">
      <b-link
        class="nav-link text-secondary font-weight-bolder"
        @click="toggleVerticalMenuActive"
      >
        <feather-icon
          icon="MenuIcon"
          size="48"
        />
      </b-link>
    </div>
  </div>
  <!-- Nav Menu Toggler -->
</template>

<script>
  import { BLink, BNavbarNav, BImg, BNavbarBrand } from 'bootstrap-vue';
  // import SearchBar from './components/SearchBar.vue';
  import DarkToggler from './components/DarkToggler.vue';
  import UserDropdown from './components/UserDropdown.vue';

  export default {
    components: {
      BLink,
      BImg,
      // Navbar Components
      BNavbarNav,
      BNavbarBrand,
      // SearchBar,
      DarkToggler,
      UserDropdown,
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>
